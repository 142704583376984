const RequestTimeframes = {
	asap: 'As soon as possible',
	'in-next-week': 'In the next week',
	'in-next-month': 'In the next month',
	'in-next-3-months': 'In the next 3 months',
	'in-next-6-months': 'In the next 6 months',
	'in-next-year': 'In the next year',
};

export default RequestTimeframes;
